import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
        import('modules/dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule),
    },
    {
        path: 'accaunt',
        loadChildren: () =>
            import('modules/accaunt/accaunt-routing.module').then(m => m.AccauntRoutingModule),
    },
    {
        path: 'search-report',
        loadChildren: () =>
            import('modules/search-report/search-report-routing.module').then(m => m.SearchReportRoutingModule),
    },
    {
        path: 'report-360',
        loadChildren: () =>
            import('modules/report-360/report-360-routing.module').then(m => m.Report360RoutingModule),
    },
    {
        path: 'manage-test',
        loadChildren: () =>
            import('modules/manage-test/manage-test-routing.module').then(m => m.ManageTestRoutingModule),
    },
    {
        path: 'master',
        loadChildren: () =>
            import('modules/master/master-routing.module').then(m => m.MasterRoutingModule),
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('modules/dashboard/dashboard-routing.module').then(
                m => m.DashboardRoutingModule
            ),
    },
    {
        path: 'login',
        loadChildren: () =>
            import('modules/login/login-routing.module').then(
                m => m.LoginRoutingModule
            ),
    },
    // {
    //     path: 'auth',
    //     loadChildren: () =>
    //         import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    // },
    // {
    //     path: 'error',
    //     loadChildren: () =>
    //         import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    // },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
