import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";

import {environment} from '../environments/environment';
import {ModalService} from 'modules/service/modal-service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,

        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,

    ],
    providers: [ModalService],
    bootstrap: [AppComponent],
})
export class AppModule {}
