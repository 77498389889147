import Swal from 'sweetalert2'

export class ModalService {
    constructor() {}

    success(text = '') {
        return Swal.fire({
            title: 'Success',
            icon: 'success',
            text: text,
            timer: 2000
        })
    }

    err(text?: string) {
        return Swal.fire({
            title: 'Error',
            icon: 'error',
            text: text,
        })
    }

    confirm(text = '') {
        return Swal.fire({
            title: 'ยืนยัน',
            text: text,
            icon: 'question',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText:'Cancel',
        })
    }

    warning(text = '') {
        return Swal.fire({
            title: 'Warning',
            icon: 'warning',
            text: text,
        })
    }

    progress(text = '') {
        return Swal.fire({
            title: 'กำลัง Update ข้อมูล',
            html: 'โปรดรอ ระบบกำลังประมวลผล',
            timer: 1000000,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
            },
        })
    }

}
