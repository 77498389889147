// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase : {
        apiKey: "AIzaSyDxW4a47dhQ5c_0r5XQNbgQGjfuaTUGhbA",
        authDomain: "mcic-uat.firebaseapp.com",
        projectId: "mcic-uat",
        storageBucket: "mcic-uat.appspot.com",
        messagingSenderId: "512070970707",
        appId: "1:512070970707:web:1b545119c3fb20ef9b85a6",
        measurementId: "G-QN29CYQZLC"
      },
    api: 'https://us-central1-mcic-uat.cloudfunctions.net/',
    redirect: 'https://missconsult.org/login',
    version: '0.0.1'
};

// export const environment = {
//   production: false,
//   firebase : {
//       apiKey: "AIzaSyBh6t0Rgrke3QQb8MC9PP9QIJ7KhVFIzXs",
//       authDomain: "mcic-sit-7eb4e.firebaseapp.com",
//       projectId: "mcic-sit-7eb4e",
//       storageBucket: "mcic-sit-7eb4e.appspot.com",
//       messagingSenderId: "312123274254",
//       appId: "1:312123274254:web:a294163dad67a0360af85f"
//     },
//   api: 'https://us-central1-mcic-sit-7eb4e.cloudfunctions.net/',
//   redirect: 'https://mcic-sit-7eb4e.firebaseapp.com/login',
//   version: '0.0.1'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
